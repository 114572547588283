import { Controller } from 'stimulus'
import swal from 'sweetalert'

export default class extends Controller {
  handleConfirm(event) {
    event.preventDefault();

    this.confirmation().then((isConfirmed) => {
      this.dispatch(isConfirmed ? 'ok' : 'cancel');

      if (isConfirmed) {
        window.location.href = this.transmitURL;
      }
    });
  }

  confirmation() {
    return swal({
      title: 'Send document to Athena',
      text: `Are you sure you want to send HR pdf of ${this.patientName} to Athena? This action can not be undone.`,
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true
        },
        confirm: {
          text: 'Send'
        }
      }
    });
  };

  get transmitURL() {
    return this.element.href;
  }

  get patientName() {
    return this.element.dataset.patientName;
  }
}
