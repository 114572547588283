import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    //if (data.location) {
    //  window.location.reload();
    //}

    if (data.location) {
      window.location = data.location;
    }

    if (data.modal) {
      $('body').
        findOrAppend('#reject-modal', '<div id="reject-modal" />').
        html(data.modal).
        find('.modal').
        modal('show');
    }
  }

  onError(event) {
    const [data, _status, xhr] = event.detail;

    if (data.location) {
      window.location = data.location;
    }

    if (data.form) {
      $('#reject-modal .modal-content').
        replaceWith(data.form);
    }
  }
}