import { Controller } from 'stimulus'

export default class extends Controller {
  log({ params: { payload } }) {
    const request =
      $.ajax({
        url: '/trace',
        data: { payload },
        type: 'POST',
        dataType: 'json'
      });

    request.fail((_xhr, _status, error) => {
      console.error('Error:', error);
    });
  }
}
